<template>
 <section  class="uk-section uk-section-secondary uk-section-large">


  <h1 class="uk-text-center uk-padding-remove">CORE: Subscription</h1>

  

 </section>
</template>

<script>
export default {

}
</script>

<style>

</style>