<template>
 <section  class="uk-section uk-section-secondary uk-section-large">
    <img :src="logo" alt="logo core" class="uk-align-center uk-animation-fade">
     <div class="uk-container uk-padding-remove">

        <div uk-grid class="uk-child-width-1-2@s uk-padding-remove">
            <div class="uk-animation-fade uk-animation-scale-left">
                <iframe width="100%" height="300px" src="https://www.youtube.com/embed/LDU_Txk06tM"></iframe>
            </div>

            <div class="uk-text-center uk-animation-fade uk-animation-scale-right">

                <h1 class="">Controla tus finanzas en tiempo real</h1>

                <h3>Centraliza la información de tus ventas, gastos, productos, servicios, personal y más:</h3>

                <CTAButton :logged="isLogged" :core="hasCore" />

            </div>
        </div>
     </div>
  
 </section>
 <section class="uk-section uk-section-muted uk-section-large">
     <div class="uk-container">
         <div uk-grid class="uk-child-width-1-1 uk-margin">
             <div class="uk-text-center">
                 <ul style="font-size:36px" class="uk-list">
                     <li>
                        <span uk-icon="icon:check; ratio:2"></span>   Maneja tus ventas desde cualquier lugar <br><br>
                     </li>
                     <li>
                        <span uk-icon="icon:check; ratio:2"></span>   Personaliza y mide tus gastos <br><br>
                     </li>
                     <li>
                        <span uk-icon="icon:check; ratio:2"></span>   Organiza rápidamente tus clientes <br><br>
                     </li>
                     <li>
                        <span uk-icon="icon:check; ratio:2"></span>   Automatiza el cálculo de nóminas <br><br>
                     </li>
                     <li>
                        <span uk-icon="icon:check; ratio:2"></span>   Mide tu rendimiento en tiempo real <br><br>
                     </li>
                 </ul>
             </div>
         </div>
         <CTAButton :logged="isLogged" :core="hasCore" class="uk-width-1-2 uk-margin uk-align-center" />
     </div>
 </section>
</template>

<script>
import  CTAButton  from '@/components/core/CTAButton.vue'
import { ref } from '@vue/reactivity'
export default {
    components:{
            CTAButton
    },
    setup(){
        const isLogged = ref(true)
        const hasCore = ref(true)
        const logo = require('../../../assets/core/logo_dark.png')

        return {
            isLogged,
            hasCore,
            logo
        }
    }

}
</script>

<style>

</style>